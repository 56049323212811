@import "base/normalize";

*, *:before, *:after {
  box-sizing: border-box;
}

body {
  background: white;

  .is-dark & {
    background: black;
  }
}

#stage {
  display: grid;
  position: relative;
  width: 100vw;
  height: 45vh;
  padding: 0 2vw;

  @media (min-width: 768px) {
    height: 75vh;
  }

  svg {
    width: 100%;
    height: 100%;
    transform-origin: center center;
  }
}

.container {
  margin: 0 calc(2vw + 8px);
}
